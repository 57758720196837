import { useAuthSlice } from "../redux/auth-slice";
import { useConfigSlice } from "../redux/config-slice";
import { sendGtmEvent, SendGtmEventProps } from "../utils/helpers/google-tags";

export const useGoogleTag = () => {
  const { user, language } = useAuthSlice();
  const { district, serviceDetails } = useConfigSlice();

  const sendGoogleTagEvent = ({ dataLayer, event }: SendGtmEventProps) => {
    // const tagManagerArgs: SendGtmEventProps = {
    //   dataLayer: {
    //     ...dataLayer,
    //     user_id: user?.id,
    //     user: user?.name || "",
    //     district: district?.name || "",
    //     district_id: district?.id || "",
    //     service_id: serviceDetails?.id || "",
    //     service: serviceDetails?.name || "",
    //     language,
    //   },
    //   event,
    // };
    // // if (process.env.NODE_ENV === "production") {
    // console.log("sendGoogleTagEvent", event.dataLayerName);
    // sendGtmEvent(tagManagerArgs);
    // }
  };

  return { sendGoogleTagEvent };
};
