import TagManager, { DataLayerArgs, TagManagerArgs } from "react-gtm-module";

{
  /*
   
event
    Purpose: The event key is used to specify the type of event being tracked. This can be any string that makes sense for your tracking needs.
    Usage: You can change "productView" to any other event name, such as "addToCart", "purchase", etc.
dataLayerName:
    Purpose: The preview key is used for Google Tag Manager's preview mode. It allows you to test changes in a specific environment before they go live.
    Usage: You can change "env-00" to any other environment identifier that you have set up in GTM.
    
*/
}

type GtmEventKeyProps = {
  event: string;
  dataLayerName: string;
  preview: string;
};
// type GtmEventProps = {
//   [key: string]: GtmEventKeyProps;
// };
export const GtmEvents = {
  home: {
    event: "home_page_view",
    dataLayerName: "home_page_data_layer",
    preview: "env-home",
  },
  offers: {
    event: "offers_page_view",
    dataLayerName: "offers_page_data_layer",
    preview: "env-offers",
  },
  branches: {
    event: "branches_page_view",
    dataLayerName: "branches_page_data_layer",
    preview: "env-branches",
  },
  branch: {
    event: "branch_page_view",
    dataLayerName: "branch_page_data_layer",
    preview: "env-branch",
  },
  brands: {
    event: "brands_page_view",
    dataLayerName: "brands_page_data_layer",
    preview: "env-brands",
  },
  blogs: {
    event: "blogs_page_view",
    dataLayerName: "blogs_page_data_layer",
    preview: "env-blogs",
  },
  blog: {
    event: "blog_page_view",
    dataLayerName: "blog_page_data_layer",
    preview: "env-blog",
  },
  brand: {
    event: "brand_page_view",
    dataLayerName: "brand_page_data_layer",
    preview: "env-brand",
  },
  staticPage: {
    event: "static_page_view",
    dataLayerName: "static_page_data_layer",
    preview: "env-static-page",
  },
  offer: {
    event: "offer_page_view",
    dataLayerName: "offer_page_data_layer",
    preview: "env-offer",
  },
  cart: {
    event: "cart_page_view",
    dataLayerName: "cart_page_data_layer",
    preview: "env-cart",
  },
  purchase: {
    event: "purchase",
    dataLayerName: "purchase_data_layer",
    preview: "env-purchase",
  },
  selectPaymentMethod: {
    event: "select_payment_method",
    dataLayerName: "payment_method_data_layer",
    preview: "env-payment",
  },
  productDetail: {
    event: "product_detail_view",
    dataLayerName: "product_detail_data_layer",
    preview: "env-product-detail",
  },
  checkout: {
    event: "checkout",
    dataLayerName: "checkout_data_layer",
    preview: "env-checkout",
  },
  orderConfirmation: {
    event: "order_confirmation",
    dataLayerName: "order_confirmation_data_layer",
    preview: "env-order-confirmation",
  },
  category: {
    event: "category_view",
    dataLayerName: "category_data_layer",
    preview: "env-category",
  },
  searchResults: {
    event: "search_results_view",
    dataLayerName: "search_results_data_layer",
    preview: "env-search-results",
  },
  wishlist: {
    event: "wishlist_view",
    dataLayerName: "wishlist_data_layer",
    preview: "env-wishlist",
  },
  userProfile: {
    event: "user_profile_view",
    dataLayerName: "user_profile_data_layer",
    preview: "env-user-profile",
  },
  signin: {
    event: "login",
    dataLayerName: "login_data_layer",
    preview: "env-login",
  },
  influencerSignIn: {
    event: "influencer_login",
    dataLayerName: "influencer_login_data_layer",
    preview: "env-influencer-login",
  },
  influencer: {
    event: "influencer_view",
    dataLayerName: "influencer_data_layer",
    preview: "env-influencer",
  },
  signUp: {
    event: "register",
    dataLayerName: "register_data_layer",
    preview: "env-register",
  },
  verifyOtp: {
    event: "verify_otp",
    dataLayerName: "verify_otp_data_layer",
    preview: "env-verify-otp",
  },
  loginByWhatsapp: {
    event: "login_by_whatsapp",
    dataLayerName: "login_by_whatsapp_data_layer",
    preview: "env-login-by-whatsapp",
  },
  registration: {
    event: "registration",
    dataLayerName: "registration_data_layer",
    preview: "env-registration",
  },
  logout: {
    event: "logout",
    dataLayerName: "logout_data_layer",
    preview: "env-logout",
  },
  contactUs: {
    event: "contact_us",
    dataLayerName: "contact_us_data_layer",
    preview: "env-contact-us",
  },
  aboutUs: {
    event: "about_us",
    dataLayerName: "about_us_data_layer",
    preview: "env-about-us",
  },
  faq: {
    event: "faq_view",
    dataLayerName: "faq_data_layer",
    preview: "env-faq",
  },
  termsAndConditions: {
    event: "terms_and_conditions_view",
    dataLayerName: "terms_and_conditions_data_layer",
    preview: "env-terms-and-conditions",
  },
  privacyPolicy: {
    event: "privacy_policy_view",
    dataLayerName: "privacy_policy_data_layer",
    preview: "env-privacy-policy",
  },
  searchProductDetails: {
    event: "search_product_details",
    dataLayerName: "search_product_details_data_layer",
    preview: "env-search-product-details",
  },
  digitalProducts: {
    event: "digital_products_view",
    dataLayerName: "digital_products_data_layer",
    preview: "env-digital-products",
  },
  payProduct: {
    event: "pay_digital_product",
    dataLayerName: "pay_digital_product_data_layer",
    preview: "env-pay-digital-product",
  },
  startPayDigitalProduct: {
    event: "start_pay_digital_product",
    dataLayerName: "start_pay_digital_product_data_layer",
    preview: "env-start-pay-digital-product",
  },
  checkoutDigitalProduct: {
    event: "checkout_digital_product",
    dataLayerName: "checkout_digital_product_data_layer",
    preview: "env-checkout-digital-product",
  },
  addToCart: {
    event: "add_to_cart",
    dataLayerName: "add_to_cart_data_layer",
    preview: "env-add-to-cart",
  },
  removeFromCart: {
    event: "remove_from_cart",
    dataLayerName: "remove_from_cart_data_layer",
    preview: "env-remove-from-cart",
  },
  updateCart: {
    event: "update_cart",
    dataLayerName: "update_cart_data_layer",
    preview: "env-update-cart",
  },
  addToWishlist: {
    event: "add_to_wishlist",
    dataLayerName: "add_to_wishlist_data_layer",
    preview: "env-add-to-wishlist",
  },
};

export type SendGtmEventProps = {
  dataLayer?: object;
  event: GtmEventKeyProps;
};

export const sendGtmEvent = ({ dataLayer, event }: SendGtmEventProps) => {
  // const GTM_ID = "GTM-WVBWH8H";

  const tagManagerArgs: DataLayerArgs = {
    // gtmId: GTM_ID,

    dataLayer,
    dataLayerName: event?.dataLayerName,
    // events: {
    //   gtm: new Date().getTime(),
    //   event: event?.event,
    // },
    // need to handle from gtm cpanel and add those preview keys
    // preview: event.preview,
  };

  // TagManager.dataLayer(tagManagerArgs);
};

// sendGtmEvent({
//   event: GtmEvents.Home,
//   dataLayer: {
//     pageType: "home",
//   },
// });

// Cart Page
// Event: "cartPageView"
// DataLayerName: "CartPageDataLayer"
// Preview: "env-cart"
// Purchase Page
// Event: "purchase"
// DataLayerName: "PurchaseDataLayer"
// Preview: "env-purchase"
// Select Payment Methods Page
// Event: "selectPaymentMethod"
// DataLayerName: "PaymentMethodDataLayer"
// Preview: "env-payment"
